import React, { useState, useEffect } from "react";
import "./Loading.css"; // Create a CSS file for loading styles

const Loading = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      // When the page has finished loading, hide the loading component
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
  ) : null;
};

export default Loading;
