import React, { useState, useEffect } from "react";
import Header from "../../container/Header/Header";
import Home from "../../container/Home/Home";
import Deals from "../../container/Our Deals/Deals";
import Reviews from "../../container/Reviews/Reviews";
import Footer from "../../container/Footer/Footer";
import About from "../../container/About/About";
import Popup from "./Popup"; // Import the DiwaliSalePopup component

const Home1 = () => {
  const [showDiwaliSalePopup, setShowDiwaliSalePopup] = useState(false);

  useEffect(() => {
    // Use setTimeout to show the popup after 2 seconds (2000 milliseconds)
    const popupTimeout = setTimeout(() => {
      setShowDiwaliSalePopup(true);
    }, 2000);

    // Cleanup the timeout when the component unmounts
    return () => {
      clearTimeout(popupTimeout);
    };
  }, []);

  // Function to close the popup
  const closeDiwaliSalePopup = () => {
    setShowDiwaliSalePopup(false);
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <Home />
      </div>
      <div>
        <About />
      </div>
      <div>
        <Deals />
      </div>
      <div>
        <Reviews />
      </div>
      <Footer />
    </div>
  );
};

export default Home1;
