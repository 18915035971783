import React from 'react'
import Landing from './landing/Landing'
import Home from './Home/Home'
import Loading from './Loading';
import { useState, useEffect } from "react";
const Diwali = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showLanding, setShowLanding] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      // When the page has finished loading, hide the loading component
      setIsLoading(false);
    });

    // Show the landing page for 5 seconds
    setTimeout(() => {
      setShowLanding(false);
    }, 5000); // 5000 milliseconds (5 seconds)
  }, []);

  return (
    <div className="app-container">
      {isLoading ? <Loading /> : showLanding ? <Landing /> : <Home />}
    </div>
  );
}

export default Diwali