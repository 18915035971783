// actions/products.js
import { db } from "../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";

// Action Creators
export const getProducts = () => async (dispatch) => {
  try {
    console.log("Fetching products...");
    const querySnapshot = await getDocs(collection(db, "products"));
    const products = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Products fetched:", products);
    dispatch({ type: "FETCH_ALL", payload: products });
  } catch (error) {
    console.error("Error fetching products: ", error.message);
  }
};

export const createProduct = (product) => async (dispatch) => {
  try {
    console.log("Creating product...", product);
    const docRef = await addDoc(collection(db, "products"), product);
    const newProduct = {
      id: docRef.id,
      ...product,
    };
    console.log("Product created:", newProduct);
    dispatch({ type: "CREATE", payload: newProduct });
  } catch (error) {
    console.error("Error creating product: ", error.message);
  }
};

// function to create company and upload image

export const createCompany = (company) => async (dispatch) => {
  try {
    console.log("Creating company...", company);
    const docRef = await addDoc(collection(db, "Company"), company);
    const newCompany = {
      id: docRef.id,
      ...company,
    };
    console.log("Company created:", newCompany);
    dispatch({ type: "CREATE", payload: newCompany });
  } catch (error) {
    console.error("Error creating company: ", error.message);
  }
}


export const getCompany = () => async (dispatch) => {
  try {
    console.log("Fetching company...");
    const querySnapshot = await getDocs(collection(db, "Company"));
    const company = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Company fetched:", company);
    dispatch({ type: "FETCH_ALL", payload: company });
  } catch (error) {
    console.error("Error fetching company: ", error.message);
  }
};
