import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import { getCompany } from "../../action/products";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ResponsiveCarousel = () => {
  // const [company, setCompany] = useState([]); // Ensure state.company is not undefined
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const company = useSelector((state) => state.products);
  console.log("Company", company);


  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      transitionTime={500}
      swipeable={true}
      stopOnHover={true}
      dynamicHeight={false}
      emulateTouch={true}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            className="carousel-button carousel-button-prev"
            onClick={onClickHandler}
            title={label}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            className="carousel-button carousel-button-next"
            onClick={onClickHandler}
            title={label}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        )
      }
    >
      {company.length > 0 ? (
        company.map((item) => (
          <div key={item.id}>
            <img
              src={item.Image}
              alt={item.company}
              className="carousel-image"
            />
          </div>
        ))
      ) : (
        <div>No companies found.</div>
      )}
    </Carousel>
  );
};

export default ResponsiveCarousel;
