import React, { useState } from 'react';
import { Card,Box, CardContent, CardMedia, Typography } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import './product.css';

const Product = ({ product, setCurrentId }) => {
  const dispatch = useDispatch();
  const [isExpanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + '...';
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        margin: 2,
        boxShadow: 3,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        height="300"
        image={product.selectedFile}
        alt={product.name}
      />
      <CardContent height="full">
        <Typography gutterBottom variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="text.primary">
            By: {product.company}
          </Typography>
          <Typography variant="body2" color="text.primary">
            Category: {product.category}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Product;
