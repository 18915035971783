import React, { useEffect, useRef } from "react";
import { Fireworks } from "fireworks-js";
import "./Landing.css"; // Import your CSS file

const Landing = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const fireworks = new Fireworks(container, {
        /* fireworks options here */
      });
      fireworks.start();
    }
  }, []);

  return (
    <div className="landing-container" ref={containerRef}>
      <div className="diwali-text">Diwali Sale Started</div>
      {/* Other content can go here */}
    </div>
  );
};

export default Landing;
