import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../container/Header/Header';
import Footer from '../../container/Footer/Footer';
import './products.css';
import Product from '../Products/Product/Product';
import { CircularProgress, Grid, TextField } from '@material-ui/core';

const Products = ({ setCurrentId }) => {
  const products = useSelector((state) => state.products);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredProducts = products.filter((product) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseName = product.name ? product.name.toLowerCase() : '';
    const lowerCaseCompany = product.company ? product.company.toLowerCase() : '';
    const lowerCaseCategory = product.category ? product.category.toLowerCase() : '';

    return (
      lowerCaseName.includes(lowerCaseQuery) ||
      lowerCaseCompany.includes(lowerCaseQuery) ||
      lowerCaseCategory.includes(lowerCaseQuery)
    );
  });

  const groupedProducts = {};

  filteredProducts.forEach((product) => {
    const company = product.company;
    if (groupedProducts[company]) {
      groupedProducts[company].push(product);
    } else {
      groupedProducts[company] = [product];
    }
  });

  return (
    <div className="home-container">
      <Header />
      <div className="product-showcase">
        {!products.length ? (
          <CircularProgress />
        ) : (
          <div>
            <div className="search-bar">
              <TextField
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {Object.keys(groupedProducts).length === 0 ? (
              <div>No products found.</div>
            ) : (
              <>
                {Object.keys(groupedProducts).map((company) => (
                  <div key={company}>
                    <h2>{company}</h2>
                    <Grid container spacing={3} className="product-grid">
                      {groupedProducts[company].map((product, index) => (
                        <Grid
                          container
                          justifyContent="center"
                          key={product._id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={3}
                        >
                          <Product
                            product={product}
                            setCurrentId={setCurrentId}
                            className="product-card"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Products;
